<template>
  <div class="top tracking_top clearfix">

    <div
      class="filters-section left"
      v-show="filterSection">
      <div class="box_date mode_box width200 set">
        <!-- <select-menu :selectVal='form.timeType' :data="modeData" :noTranslate="false" :callback='changeMode'></select-menu> -->
        <el-select
          v-model="form.timeType"
          :placeholder="$t('lang.choice')"
          @visible-change='toggleMode'
          @change='changeMode'>
          <el-option
            v-for="item in modeData"
            :key="item.value"
            :label="`${$t('lang.' + item.label)}`"
            :value="item.value"
            :disabled='item.disabled'>
          </el-option>
        </el-select>
      </div>
      <div
        class="box_date collection_box width200 set"
        v-if='form.timeType != "0"'>
        <el-dropdown
          trigger="click"
          placement="bottom-start"
          :class='{"not_allowed": form.timeType == "0"}'
          @visible-change='getCollectionList'
          @command="handleCommand">
          <span class="el-dropdown-link">
            <i class="iconfont icon-trackcollection"></i>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu
            slot="dropdown"
            class='collection_list'
            v-infinite-scroll="moreCollection"
            :class='{length: collectionList.length}'>
            <el-dropdown-item
              v-for='(item, idx) in collectionList'
              :key='idx'
              :command="item"
              :icon="active.collection.id == item.id ? 'el-icon-check' : 'iconfont'">
              <el-tooltip
                popper-class="collection_delete_prompt"
                effect="dark"
                :content='$t("lang.deleteCollection")'
                placement="right">
                <el-button>
                  <span class='collection_name'>{{ item.name }}</span>
                  <i
                    class="el-icon-close"
                    @click.stop='deleteCollection(item)'></i>
                </el-button>
              </el-tooltip>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="box_date source_box width200 set">
        <!-- <select-menu :selectVal='form.peerId' :data="typeData" :noTranslate="true" :callback='changeSource'></select-menu> -->
        <source-classify-select
          :init='init'
          v-if='visible.device'
          :multiple='form.timeType == "2"'
          :device='form'
          @changeSource='changeSource'></source-classify-select>
      </div>
      <div class="box_date time_box width200 set">
        <div
          class='end_time pr'
          v-if='form.timeType == "2"'>
          <i class="el-icon-date"></i>
        </div>
        <el-date-picker
          v-else
          :default-value='new Date()'
          v-model="form.startTime"
          prefix-icon='el-icon-date'
          :disabled='form.timeType != "1"'
          popper-class="pick_date__top_time"
          type="datetime"
          @change='changeTime'
          :picker-options="{ disabledDate: disabledTime }">
        </el-date-picker>
      </div>
      <span class="iconfont icon-cc-arrow-circle-right f_left date-arrow">
      </span>
      <div class="box_date time_box width200">
        <div
          class='end_time pr'
          v-if='form.timeType != "1"'>
          <i class="el-icon-date"></i>
          Now
        </div>
        <el-date-picker
          v-else
          :default-value='new Date()'
          v-model="form.endTime"
          prefix-icon='el-icon-date'
          :disabled='form.timeType != "1"'
          popper-class="pick_date__top_time"
          type="datetime"
          @change='changeTime'
          :picker-options="{ disabledDate: disabledTime }">
        </el-date-picker>
      </div>
      <el-button
        type="success"
        class='filter'
        @click.stop='filtersTracking'>{{ $t('lang.filters') }}</el-button>
      <div
        class="add_collection f_right"
        v-if='visible.collectionIcon && allMarkerLength'
        @click.stop='addCollection'>+ {{$t('lang.addCpllection')}}</div>
    </div>
    <div class="right rightFl">
      <div
        class="info map"
        v-show="$route.name == 'SourceMap'">
        {{ $t("lang.refreshInterval") }}
        <div class="fresh box">
          <select-menu
            :data="freshData"
            :noTranslate="true"
            :callback="changeFresh"
            :selectVal="freshTime"></select-menu>
        </div>
        <i class="red"></i>
        <i class="green"></i>
        <span class="xy">{{ $t("lang.coordinate") }}(
          <span>{{ mapNum.livingPosition }}</span>/{{ mapNum.totalPosition }})</span>
      </div>

      <!-- 列表切换 -->
      <div class="info icon">
        <i
          class="iconfont sourceListIcon"
          :class="{ tabActive: $route.name == 'SourceContent' }"
          @click="jump('SourceContent')"></i>
      </div>
      <div class="info icon">
        <!--                <i class="iconfont el-icon-arrow-down"></i>-->
        <el-dropdown
          @command="changeSize"
          placement="bottom-end">
          <span class="el-dropdown-link">
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu
            slot="dropdown"
            class="selectSizeBox">
            <el-dropdown-item
              command="l"
              :class="[showSizeClass == 'l' ? 'green' : '']">
              <span
                class="iconfont icon-largesize"
                style="padding-right: 8px"></span>{{ $t("lang.largeSize") }}</el-dropdown-item>
            <el-dropdown-item
              command="m"
              :class="[showSizeClass == 'm' ? 'green' : '']">
              <span
                class="iconfont"
                style="padding-right: 8px">&#xe63d;</span>{{ $t("lang.mediumSize") }}</el-dropdown-item>
            <el-dropdown-item
              command="s"
              :class="[showSizeClass == 's' ? 'green' : '']">
              <span
                class="iconfont"
                style="padding-right: 8px">&#xe6ef;</span>{{ $t("lang.smallSize") }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="info icon">
        <i
          class="iconfont sourceInfoIcon"
          :class="{ tabActive: $route.name == 'SourceList' }"
          @click="jump('SourceList', 'jump')"></i>
      </div>
      <div class="info icon">
        <i
          class="iconfont i-map"
          :class="{ tabActive: $route.name == 'SourceMap' }"
          @click="jump('SourceMap')"></i>
      </div>
      <div class="info icon">
        <i
          class="iconfont icon-zidongdingwei"
          :class="{ tabActive: $route.name == 'SourceMapTrack'}"
          @click="chooseTrack"></i>
      </div>
    </div>
    <!-- 轨迹弹框 -->
    <map-track
      ref="mapTrack"
      v-show="trackAlert"></map-track>
    <!-- addSource -->
    <add-source
      v-if="add.show"
      :data="add"
      @queryList="queryInfo"></add-source>
    <!-- add collection -->
    <track-collection
      :show.sync='visible.addCollection'
      :currentTracking='currentTracking'>
    </track-collection>
  </div>
</template>
<script>
import Bus from '@/assets/js/vueBus.js'
import { mapMutations, mapState } from 'vuex'
import { MySourceStatus, MySourceType, SelectBySorts } from '@/config'
import SelectMenu from '@/components/SelectMenu'
import AddSource from './dialog/addSource'
import { mySourceAnalyze } from '@/assets/js/googleAnalyze/mySource.js'
import mapTrack from './dialog/mapTrackAlert'
import SourceClassifySelect from '@/components/SourceClassifySelect'
import trackCollection from './dialog/trackCollection'

export default {
  name: 'sourceTop',
  components: {
    SelectMenu,
    AddSource,
    mapTrack,
    SourceClassifySelect,
    trackCollection
  },
  props: {
    query: Function,
    mapNum: {
      type: Object,
      default: () => {
        return {
          livingPosition: 0,
          totalPosition: 0
        }
      }
    }
  },
  computed: {
    ...mapState({
      // 映射
      userBehaviors: (state) => (state.userBehaviors ? state.userBehaviors : {}),
      showSizeClass () {
        return this.$store.state.common.showDeviceSize
      }
    })
  },
  data () {
    return {
      visible: {
        addCollection: false,
        collectionIcon: false,
        device: true
      },
      multiple: false,
      collectionList: [],
      trackAlert: false, // 轨迹弹框是否显示
      statusData: MySourceStatus,
      typeData: MySourceType,
      selectBySort: SelectBySorts,
      modeData: [
        { value: '0', label: 'realTime' },
        { value: '1', label: 'history' },
        { value: '2', label: 'multiTracking' }
      ],
      allMarkerLength: 0, // 所有marker点的数量
      pageSize: 15,
      total: 0,
      freshTime: 60,
      gpsDuration: 120,
      freshData: [
        // map更新数据
        { label: 60, value: 60 },
        { label: 30, value: 30 },
        { label: 20, value: 20 },
        { label: 10, value: 10 }
      ],
      form: {
        startTime: this.$route.query.startTime,
        endTime: this.$route.query.endTime,
        timeType: this.$route.query.timeType,
        peerId: this.$route.query.peerId,
        peerName: this.$route.query.peerName,
        sourceType: this.$route.query.sourceType,
        status: ''
      },
      active: {
        collection: {
          id: ''
        }
      },
      init: true,
      filter: {
        // 筛选过滤参数
        living: true,
        online: true,
        offline: true,
        noCamera: true,
        pack: true,
        anywhere: true,
        gridPack: true,
        gridSdi: true,
        ext: true,
        pageNum: 1,
        pageSize: 15,
        condition: '',
        bookmardIds: 'all',
        unClassified: true,
        token: true,
        onlineFirst: true,
        liveFirst: false,
        globalGrid: true,
        gridViaToken: true
      },
      selectSort: '', // 下拉排序
      defaultBookmark: {
        // 默认bookmark选中的值l
        bookmardIds: ['all'],
        token: true,
        unClassified: true
      },
      add: {
        // add ext
        show: false
      },
      show: false,
      lastFilterStatus: [],
      lastFilterType: [],
      showFilter: false,
      filterSection: false,
      filterSectionAddBtn: false,
      lastTime: {
        startTime: null,
        endTime: null
      },
      currentTracking: {
        peerName: '',
        peerId: '',
        startTime: '',
        endTime: ''
      },
      collectionParams: {
        pageSize: 15,
        currentPage: 1
      },
      multiplePeerIdArr: [],
      lastMultiplePeerIdArr: []
    }
  },
  created () {
    this.form.startTime = Number(this.$route.query.startTime) || null
    this.form.endTime = Number(this.$route.query.endTime) || null
    // 如果是实时tracking，开始时间为null，结束时间为Now
    if (this.$route.query.timeType == '0') {
      this.form.startTime = null
    }
    this.queryInfo(this.$route.name, 'init')
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    Bus.$on('updateMySource', () => {
      this.queryInfo(this.$route.name, 'init')
    })
    Bus.$on('allMarkerLength', length => {
      this.allMarkerLength = length
    })
  },
  mounted () {
    this.currentTracking.peerName = this.$route.query.peerName
    this.changeCurTracking(this.$route.query)
    this.visible.collectionIcon = this.$route.query.timeType == '1'
    let collect = sessionStorage.getItem('collect')
    if (!collect) return
    collect = JSON.parse(collect)
    if (!collect.isSelect) return
    this.active.collection = collect
    if (this.$route.query.timeType != '2') return
    this.multiplePeerIdArr = this.$route.query.multiple.split(',')

    document.addEventListener('click', this.clickEvent, true)
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
    document.removeEventListener('click', this.clickEvent, true)
  },
  methods: {
    // 搜索函数
    ...mapMutations('common', ['CHANGE_SOURCESize']),
    clickEvent (e) {
      const tar = e.srcElement || e.target
      try {
        const flag = document.querySelector('.source_classify_select').contains(tar)
        const clickInput = document.querySelector('.el-cascader__search-input').contains(tar)
        if (!flag && !clickInput) $('.source_classify_select').hide()
      } catch (error) { }
    },
    changeSource (val) {
      if (this.form.timeType == '2') {
        this.handleMultipleMode(val)
        return
      }
      this.form.sourceType = val[0][0]
      const peerId = this.form.peerId
      this.form.peerId = val[0][1]
      this.form.peerName = val[0][3]
      let collect = sessionStorage.getItem('collect')
      if (!collect) return
      collect = JSON.parse(collect)
      if (peerId != val[0][1]) {
        collect.isSelect = false
        this.active.collection = {}
        sessionStorage.setItem('collect', JSON.stringify(collect))
      }
    },
    // 处理多选模式
    handleMultipleMode (val) {
      // 上次选中的多路设备
      this.lastMultiplePeerIdArr = this.multiplePeerIdArr
      this.multiplePeerIdArr = []
      this.currentSourceStatus = null
      for (let i = 0; i < val.length; i++) {
        this.multiplePeerIdArr.push(`${val[i][0]}-${val[i][1]}`)
        // 找出offline的设备
        if (this.currentSourceStatus === '0') {
          continue
        }
        this.currentSourceStatus = val[i][2]
      }
    },
    disabledTime (time) {
      const today = new Date().getTime()
      return time > today
    },
    changeTime () {
      let collect = sessionStorage.getItem('collect')
      if (!collect) return
      collect = JSON.parse(collect)
      if (this.form.endTime && typeof this.form.endTime == 'object') {
        this.form.endTime = this.form.endTime.getTime()
      }
      if (this.form.startTime && typeof this.form.startTime == 'object') {
        this.form.startTime = this.form.startTime.getTime()
      }
      if (this.form.endTime != collect.endTime || this.form.startTime != collect.startTime) {
        collect.isSelect = false
        this.active.collection = {}
        sessionStorage.setItem('collect', JSON.stringify(collect))
      }
    },
    // 选中collection
    handleCommand (item) {
      console.log(item)
      this.active.collection = item
      this.form.peerId = item.peerId
      this.form.peerName = item.peerName
      // this.form.status = item.peerName
      this.form.sourceType = item.type
      this.form.endTime = Number(item.endTime)
      this.form.startTime = Number(item.startTime)
      // this.currentTracking.peerName = item.peerName
      item.isSelect = true
      sessionStorage.setItem('collect', JSON.stringify(item))
    },
    // 删除收藏轨迹
    async deleteCollection (item) {
      const promptText = this.collectionList.length == 1 ? this.$t('lang.deleteOnlyCollectionPrompt') : this.$t('lang.deleteCollectionPrompt')
      await this.$confirm(promptText, '', {
        confirmButtonText: this.$t('lang.confirmBtn'),
        cancelButtonText: this.$t('lang.cancel'),
        type: ''
      }).then(async () => {
        await this.axios({
          method: 'post',
          url: '/ccp/tvucc-device/favorite/delFavorite',
          data: {
            id: item.id
          },
          headers: { 'Content-Type': 'application/json;charset=UTF-8' }
        }).then(res => {
          if (res.status == 200 && res.data.errorCode == '0x0') {
            this.$message.success(this.$t('lang.success'))
            return
          }
          this.$message.error(res.data.errorInfo)
        }).catch(() => {})
      }).catch(() => {})
    },
    // 删除当前选中的轨迹
    deletedCurrentCollection (item) {
      if (this.active.collection.id == item.id) {

      }
    },
    search (val) {
      mySourceAnalyze('mySourceSearch', this.$route.path) // 谷歌统计
      this.filter.pageNum = 1
      this.filter.condition = val
      this.queryInfo('', 'filter')
    },
    // add collection
    addCollection () {
      this.visible.addCollection = true
    },
    // 点击定位按钮
    chooseTrack () {
      if (this.$route.name == 'SourceMapTrack') return
      this.$refs.mapTrack.init()
    },
    // 懒加载
    moreCollection () {
      if (!this.hasNextPage) return
      this.collectionParams.currentPage++
      this.getCollectionList()
    },
    // 获取收藏列表
    getCollectionList (val) {
      if (val || this.form.timeType == '0') {
        this.collectionParams.currentPage = 1
        this.collectionList = []
      }
      if (val === false || this.form.timeType == '0') return
      if (this.collectionParams.currentPage == 1) {
        this.collectionList = []
        this.hasNextPage = true
      }
      this.axios({
        method: 'post',
        url: '/ccp/tvucc-device/favorite/listFavorite',
        data: this.collectionParams,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
      }).then(res => {
        if (res.status == 200 && res.data.errorCode == '0x0') {
          this.hasNextPage = res.data.result.hasNextPage
          if (res.data.result.isFirstPage) {
            this.collectionList = res.data.result.list
            return
          }
          this.collectionList = this.collectionList.concat(res.data.result.list)
        }
      }).catch(() => {})
    },
    toggleMode () {
      $('.time_box .el-date-editor.el-input .el-input__inner').click()
    },
    // 更改时间类型
    changeMode (val) {
      this.init = false
      this.form.timeType = val
      // 重新注册设备选择框
      this.visible.device = false
      this.$nextTick(() => {
        this.visible.device = true
      })
      // 取消收藏的轨迹
      this.active.collection = {}
      // 多路轨迹
      if (val === '2') {
        this.form.peerId = ''
        this.form.sourceType = ''
        this.form.peerName = ''
        this.form.endTime = null
        this.form.startTime = null
        this.multiplePeerIdArr = this.lastMultiplePeerIdArr
      }
      this.lastMultiplePeerIdArr = this.multiplePeerIdArr
      // 历史模式
      if (val === '1') {
        this.form.peerName = ''
        this.form.peerId = ''
      }
      // 实时模式
      if (val === '0') {
        this.lastTime.startTime = this.form.startTime
        this.lastTime.endTime = this.form.endTime
        this.form.startTime = null
        this.form.endTime = null
        this.form.peerName = ''
        this.form.peerId = ''
        return
      }
      this.form.startTime = this.lastTime.startTime
      this.form.endTime = this.lastTime.endTime
    },
    // 筛选
    async filtersTracking () {
      let currentSourceStatus
      // 多路轨迹
      if (this.form.timeType == '2') {
        // 没有选轨迹
        if (!this.form.peerId) {
          this.$message.error(this.$t('lang.selectCollection'))
          return
        }
        // 没有选设备
        if (!this.multiplePeerIdArr.length) {
          this.$message.error(this.$t('lang.sourceEmpty'))
          return
        }
        if (this.currentSourceStatus == '0') {
          this.$message.error(this.$t('lang.offSourNotReal'))
          return
        }
      }
      // 没有选轨迹
      if (!this.form.peerId) {
        this.$message.error(this.$t('lang.sourceEmpty'))
        return
      }
      await this.axios.get(`/ccp/tvucc-device/device/getDevice?peerId=${this.form.peerId}`)
        .then(res => {
          if (res.status == 200 && res.data.errorCode == '0x0') {
            currentSourceStatus = res.data.result.status
          }
        }).catch(() => {})
      if (currentSourceStatus == '0' && this.form.timeType == '0') {
        this.$message.error(this.$t('lang.offSourNotReal'))
        return
      }
      const duration = this.form.endTime - this.form.startTime
      if (this.form.timeType == '1' && (!this.form.startTime || !this.form.endTime || !duration)) {
        this.$message.error(this.$t('lang.durEmpty'))
        return
      }
      this.gpsDuration = localStorage.getItem('gpsDuration') || 120
      if (this.form.timeType == '1' && (duration > this.gpsDuration * 60 * 1000 || duration < 0)) {
        this.$message.error(this.$t('lang.durationTime', { time: this.gpsDuration }))
        return
      }
      if (this.form.timeType == '0') {
        this.lastTime.startTime = null
        this.lastTime.endTime = null
      }
      // 多路轨迹
      if (this.form.timeType != '2') {
        this.multiplePeerIdArr = []
      }
      // 更改当前的tracking
      this.changeCurTracking(this.form)
      this.visible.collectionIcon = this.form.timeType == '1'
      // 跳转
      const obj = {
        startTime: this.form.timeType == '0' ? null : this.form.startTime,
        endTime: this.form.endTime,
        timeType: this.form.timeType,
        sourceType: this.form.sourceType,
        peerId: this.form.peerId,
        peerName: this.currentTracking.peerName
      }
      if (this.form.timeType == '2') {
        obj.multiple = this.multiplePeerIdArr.join(',')
      }
      this.lastMultiplePeerIdArr = []
      this.$emit('changeTrack', obj)
    },
    changeCurTracking (obj) {
      this.currentTracking = {
        peerName: obj.peerName,
        peerId: obj.peerId,
        startTime: obj.startTime && typeof obj.startTime == 'object' ? String(obj.startTime.getTime()) : String(obj.startTime),
        endTime: obj.endTime && typeof obj.endTime == 'object' ? String(obj.endTime.getTime()) : String(obj.endTime)
      }
    },
    openBookmarkList () {
      mySourceAnalyze('mySourceBookmarkOpen', this.$route.path) // 谷歌统计
    },
    // 谷歌统计处理
    handleAnalyze (allVal, list, lastArr, type) {
      if (allVal != '' && allVal.all === true) {
        mySourceAnalyze(`${type}AllSelected`, this.$route.path) // 谷歌统计
        this[lastArr] =
          type == 'mySourceStatus' ? ['online', 'noCamera', 'living', 'offline'] : [
            'pack',
            'anywhere',
            'gridPack',
            'gridSdi',
            'ext',
            'gridViaToken',
            'globalGrid'
          ]
        return
      }
      if (allVal != '' && allVal.all === false) {
        mySourceAnalyze(`${type}AllUnselect`, this.$route.path) // 谷歌统计
        this[lastArr] = []
        return
      }
      list.map((v, i) => {
        if (!this[lastArr].includes(v)) { mySourceAnalyze(`${type}${v}Selected`, this.$route.path) } // 谷歌统计
      })
      this[lastArr].map((v, i) => {
        if (!list.includes(v)) { mySourceAnalyze(`${type}${v}Unselect`, this.$route.path) } // 谷歌统计
      })
      this[lastArr] = list
    },
    // 筛选
    changeFilter (obj, allVal, list, type) {
      // 谷歌统计
      if (list && type == 'status') {
        this.handleAnalyze(allVal, list, 'lastFilterStatus', 'mySourceStatus')
      }
      if (list && type == 'type') {
        this.handleAnalyze(allVal, list, 'lastFilterType', 'mySourceType')
      }
      Object.keys(obj).map((v) => {
        // this.filter 过滤参数对象
        this.filter[v] = obj[v]
      })
      this.filter.pageNum = 1
      this.$route.name != 'SourceMap' && this.queryInfo() // 筛选后查询接口
    },
    // 排序
    changeSort (val) {
      mySourceAnalyze(`mySourceSort${val}`, this.$route.path) // 谷歌统计
      this.filter.pageNum = 1
      this.filter.onlineFirst = val == 'onlineFirst'
      this.filter.liveFirst = val == 'liveFirst'
      this.$route.name != 'SourceMap' && this.queryInfo()
    },
    // 分页
    handleCurrentChange (val) {
      this.filter.pageNum = val
      this.queryInfo()
    },
    handleSizeChange (val) {
      this.filter.pageSize = val
      this.filter.pageNum = 1
      this.queryInfo()
    },
    // 更改刷新时间
    changeFresh (val) {
      this.freshTime = val
      this.queryInfo(this.$route.name, 'frequency')
    },
    // 路由跳转
    jump (val, type) {
      mySourceAnalyze(`mySource${val}`, this.$route.path) // 谷歌统计
      if (val == this.$route.name) return
      const routeName = val || this.$route.name
      this.$router.push({ name: routeName })
    },
    // 地图所需数据
    emitMapData (flag) {
      const obj = {
        query: this.filter,
        freshTime: this.freshTime,
        queryFlag: flag
      }
      this.$emit('filterMap', obj)
      this.show = true
    },
    initInfo () {
      const routeName = this.$route.name
      this.axios.post('/ccp/tvucc-user/userDevice/mySourceWithInit', {
        apiType: routeName
      }).then((res) => {
        if (res.data.errorCode == '0x0' && res.data.errorInfo == 'Success') {
          this.showFilter = true
          const result = res.data.result
          const userBehaviors = result.param
          const list = result.content
          // status
          if (userBehaviors.living) this.lastFilterStatus.push('living')
          if (userBehaviors.noCamera) this.lastFilterStatus.push('noCamera')
          if (userBehaviors.offline) this.lastFilterStatus.push('offline')
          if (userBehaviors.online) this.lastFilterStatus.push('online')
          // type
          if (userBehaviors.pack) this.lastFilterType.push('pack')
          if (userBehaviors.anywhere) this.lastFilterType.push('anywhere')
          if (userBehaviors.gridPack) this.lastFilterType.push('gridPack')
          if (userBehaviors.gridSdi) this.lastFilterType.push('gridSdi')
          if (userBehaviors.ext) this.lastFilterType.push('ext')
          if (userBehaviors.gridViaToken) { this.lastFilterType.push('gridViaToken') }
          if (userBehaviors.globalGrid) { this.lastFilterType.push('globalGrid') }
          // 初始化筛选条件
          if (userBehaviors) {
            Object.keys(this.filter).map((v) => {
              Object.keys(userBehaviors).map((K) => {
                if (v == K) {
                  this.filter[v] = userBehaviors[K]
                }
              })
            })
          }
          // 用来处理筛选选中的状态
          this.statusData.map((v, k) => {
            const obj = v
            obj.isChecked = this.filter[v.value] || false
            this.$set(this.statusData, k, obj)
          })
          this.typeData.map((v, k) => {
            const obj = v
            obj.isChecked = this.filter[v.value] || false
            this.$set(this.typeData, k, obj)
          })
          this.selectSort = this.$options.filters.FORMATSORT(this.filter)
          // bookmark
          this.defaultBookmark.bookmardIds = this.filter.bookmardIds ? this.filter.bookmardIds == 'all' ? ['all'] : this.filter.bookmardIds.split(',') : []
          this.filter.unClassified &&
              this.defaultBookmark.bookmardIds.push('unClassified')
          this.filter.token && this.defaultBookmark.bookmardIds.push('token')
          this.show = true
          this.filter.pageSize = 15

          // 根据不同的typeApi 做不同的操作
          if (routeName == 'SourceContent' || routeName == 'SourceList') {
            this.total = list.total || 0
            this.query(list)
            this.$emit('sendMySourceSockect', list) // 监听发送websocket
            Bus.$emit('changeFilter', true)
          }
          if (routeName == 'SourceMap') {
            let flag = false
            this.emitMapData(flag)
          }
        }
      }).catch(() => {
        Bus.$emit('changeFilter', true)
      })
    },
    queryInfo (val, type) {
      // 查询数据
      const routeName = val || this.$route.name
      let result = {}

      if (!(routeName == 'SourceMap' && type == 'filter')) {
        Bus.$emit('changeFilter', false) // 监听状态
      }

      if (routeName == 'SourceContent' || routeName == 'SourceList') {
        if (type == 'init') {
          this.initInfo()
        } else {
          const data = JSON.parse(JSON.stringify(this.filter))
          if (routeName == 'SourceList') {
            data.battery = true
            data.bookmarks = true
          }
          this.axios.post('/ccp/tvucc-user/userDevice/pageUserSource', data, {
            contentType: 'application/json;charset=UTF-8',
            isCancelToken: true
          }).then((res) => {
            if (
              res.data.errorCode == '0x0' &&
                res.data.errorInfo == 'Success'
            ) {
              result = res.data.result
              this.total = result.total || 0
              this.query(result)
              this.$emit('sendMySourceSockect', result) // 监听发送websocket
              Bus.$emit('changeFilter', true)
            }
          }).catch(() => {})
        }
      }

      if (routeName == 'SourceMap') {
        if (type == 'init') {
          this.initInfo()
        } else {
          const flag = type != 'frequency'
          this.emitMapData(flag)
        }
      }
    },
    addSource () {
      // 添加外部视频源
      this.add.show = true
      mySourceAnalyze('mySourceAddExtOpen', this.$route.path) // 谷歌统计
    },
    filterSectionToggle () {
      this.filterSection = !this.filterSection
    },
    handleResize () {
      if (document.body.clientWidth > 1279) {
        this.filterSection = true
        this.filterSectionAddBtn = true
      }

      if (document.body.clientWidth < 1280) {
        this.filterSection = false
        this.filterSectionAddBtn = false
        $('.bookmarkList-cascader').hide()
      }
    },
    changeSize (command) {
      this.CHANGE_SOURCESize(command)
      switch (command) {
        case 's':
          mySourceAnalyze('mySourceChangeSmall', this.$route.path)
          break
        case 'm':
          mySourceAnalyze('mySourceChangeMedium', this.$route.path)
          break
        case 'l':
          mySourceAnalyze('mySourceChangeLarge', this.$route.path)
      }
    }
  },
  watch: {
    $route (to, from) {
      // 切换及后退会触发路由变化
      this.queryInfo(to.name, 'frequency')
    }
  }
}
</script>
<style lang="less">
.collection_list.length {
  padding: 6px 0;
}
.collection_list {
  padding: 0;
  border: none;
  .el-icon-check {
    color: rgba(57, 170, 80);
  }
  .el-icon-close {
    visibility: hidden;
  }
  .el-dropdown-menu__item:hover .el-icon-close {
    visibility: visible;
  }
  li.el-dropdown-menu__item {
    width: 220px;
    height: 27px;
    line-height: 27px;
    padding: 0 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    > i {
      // display: inline-block;
      float: left;
      width: 14px;
      height: 14px;
      line-height: 27px;
    }
    .collection_name {
      display: inline-block;
      text-align: left;
      width: 158px;
    }
    button {
      border: none;
      background: transparent;
      padding: 0;
      height: 27px;
      line-height: 27px;
      span {
        float: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
.collection_delete_prompt.el-tooltip__popper {
  margin-left: 22px;
  height: 34px;
  background: #444;
  .popper__arrow::after {
    border-right-color: #444;
  }
}
.pick_date__top_time {
  top: 155px;
}
</style>
<style lang="less" scoped>
.green {
  color: #33ab4f;
}
.tracking_top {
  ::v-deep .el-input .el-input__inner {
    border: 1px solid #444;
  }
  ::v-deep .el-input.is-disabled .el-input__inner {
    background: #252525;
    color: #fff;
    border: 1px solid #444;
  }
  ::v-deep .el-button--success.filter {
    width: 64px;
    height: 32px;
    line-height: 32px;
    padding: 0;
  }
}
.icon-cc-arrow-circle-right {
  color: #666;
  margin-right: 9px;
  margin-top: 6px;
}
.box_date {
  .end_time {
    background: #252525;
    color: #fff;
    border: 1px solid #444;
    width: 100%;
    height: 32px;
    line-height: 32px;
    border-radius: 5px;
    text-align: left;
    padding-left: 33px;
    box-sizing: border-box;
    cursor: not-allowed;
    i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
    }
  }
}
@media (min-width: 1280px) and (max-width: 1599px) {
  .left {
    .box {
      width: 134px !important;
      margin-right: 15px;
      position: relative;

      &.width200 {
        margin-right: 10px !important;
      }
    }
  }
}

@media (max-width: 917px) {
  .left {
    .box {
      margin-top: 5px;
      margin-right: 10px;

      &.width200 {
        width: 163px !important;
      }
    }
  }
}

.top {
  padding: 37px 0.5% 24px 0;

  .sourceAddIcon:before {
    content: '\e602';
    font-size: 18px;
    cursor: pointer;
  }

  .left {
    float: left;

    .input-box {
      margin-right: 15px;
    }
  }

  .right {
    float: right;

    margin-top: 2px;

    .info {
      float: left;
      // ::v-deep .paginationBox{
      //     height: 32px;
      //     line-height: 32px;
      //     .page_dropdown{
      //         display: none;
      //     }
      // }
    }

    .map {
      i {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        margin-left: 3px;

        &.red {
          background: #d03f39;
        }

        &.green {
          background: #33ab4f;
        }
      }

      .fresh {
        width: 70px;
        display: inline-block;
        margin-right: 5px;
      }

      .xy {
        margin-left: 5px;

        span {
          color: #d03f39;
        }
      }
    }
    .icon {
      margin-left: 10px;
      color: #999;

      .el-dropdown-link {
        cursor: pointer;
        color: #dbdbdb;
      }

      i {
        position: relative;
        top: -3px;

        &:before {
          font-size: 28px;
        }

        &.tabActive {
          color: #dbdbdb;
        }
      }

      .el-icon-arrow-down {
        top: 6px;
        left: -5px;
      }

      .el-icon-arrow-down:before {
        font-size: 8px;
        font-weight: 900;
      }
    }
  }

  .box {
    ::v-deep .select-box {
      .el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
  }

  .sourceListIcon:before {
    content: '\E684';
    font-size: 24px;
    cursor: pointer;
  }

  .sourceInfoIcon:before {
    content: '\E701';
    font-size: 24px;
    cursor: pointer;
  }
}

.filters-toggle {
  border: 1px solid #666666 !important;
  // padding: 2px 20px;
  line-height: 32px;
  height: 32px;
  border-radius: 5px;
  cursor: pointer;
  color: #999999;
  width: 100px !important;
  display: none !important;
}

.filters-section {
  .box_date {
    margin-right: 15px;
    position: relative;
    float: left;
    height: 32px;
    line-height: 32px;
  }
  .mode_box {
    width: 130px;
  }
  .collection_box {
    width: 60px;
    background: #444;
    border-radius: 4px;
    .el-dropdown {
      color: #fff;
    }
    i:focus {
      outline: none;
    }
    .not_allowed {
      [role='button'],
      .iconfont {
        cursor: not-allowed;
      }
    }
  }
  .source_box {
    width: 260px;
    ::v-deep .el-cascader__tags {
      width: 100%;
      height: 100%;
      .el-cascader__search-input {
        color: #fff;
        margin-top: 0;
        margin-left: 16px;
        padding-right: 30px;
      }
    }
  }
  .time_box {
    width: 172px;
    ::v-deep .el-date-editor.el-input {
      width: 100%;
    }
  }
  .add_collection {
    margin-left: 56px;
    line-height: 32px;
    color: #33ab4f;
    cursor: pointer;
  }
  ::v-deep .el-cascader,
  ::v-deep .el-input__inner,
  ::v-deep .el-cascader__search-input {
    height: 32px !important;
    line-height: 32px;
  }
  .box {
    width: 160px;
    margin-right: 15px;
    position: relative;
    float: left;

    &.set {
      height: 32px;
      line-height: 32px;
    }

    &.width200 {
      margin-right: 20px;
    }

    ::v-deep .el-dropdown {
      width: 100%;
      height: 32px;
    }

    ::v-deep .el-dropdown .el-button.el-button--primary {
      padding: 9px 20px;
    }

    ::v-deep .tree-btn {
      padding: 6px 20px;
    }

    ::v-deep .tree {
      top: 36px;
    }
  }
}

.addBtn {
  cursor: pointer;
  position: relative;
  top: 3px;
}

// @media (max-width: 1154px) {
@media (max-width: 1279px) {
  .el-select {
    width: 100% !important;
  }

  .filters-toggle {
    display: inline-block !important;
    margin-right: 20px;
  }

  .filters-section {
    display: flex;
    position: relative;
    top: 10px;
    z-index: 99;
    left: 0;
    width: 100%;

    .box {
      width: 100%;

      .select-box {
        width: 100%;

        ::v-deep .el-select {
          width: 100% !important;
        }
      }
    }

    .last-select {
      margin-right: 0px;
    }
  }

  .left {
    display: flex;
  }

  .top {
    position: relative;
    //padding: 37px 0.5% 44px 0;
    .rightFl {
      position: absolute;
      right: 0;
    }
  }

  .iconfont {
    padding-top: 5px;
    padding-left: 2px;
  }
}

@media (max-width: 771px) {
  .top {
    .left {
      .filters-section {
        width: 100%;

        .box {
          width: 100%;

          .select-box {
            width: 100%;

            .el-select {
              width: 100%;
            }
          }
        }
      }
    }

    .filters-section {
      position: relative;
      display: block;
      left: 0;
      top: 0;
      //width: auto;
      .box {
        width: 100%;
      }

      .select-box {
        width: 100%;

        ::v-deep .el-select {
          width: 100%;
        }
      }
    }

    .rightFl {
      position: relative;
      float: left;
      margin-left: -8px;
    }

    .box.set {
      ::v-deep .el-select {
        width: 100%;
      }
    }
  }

  .el-select {
    width: 100% !important;
  }
}

::v-deep .selectSizeBox {
  top: 20px;
  left: 50px;
}
</style>
