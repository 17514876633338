<template>
  <el-dialog
    :title="$t('lang.trackName')"
    :visible.sync="show"
    width="620px"
    :center="true"
    :close-on-click-modal="false"
    class="tracking_select"
    @close="cancel"
    style="z-index: 2005; margin-top: 100px">
    <div class="content">
      <el-form
        :model="tracking"
        ref="event"
        label-position="top"
        :rules="rules">
        <el-form-item
          :label="$t('lang.autoType')"
          :label-width="formLabelWidth">
          <el-select
            v-model="tracking.timeType"
            :placeholder="$t('lang.choice')"
            @change='changeMode'>
            <el-option
              v-for="item in modeData"
              :key="item.value"
              :label="`${$t('lang.' + item.label)}`"
              :value="item.value"
              :disabled='item.disabled'>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-show='tracking.timeType == "2"'
          :label="$t('lang.trackCollection')"
          :label-width="formLabelWidth">
          <el-select
            v-scroll="bindScroll"
            v-model="collection.name"
            value-key="id"
            @visible-change='toggleCollection'
            @change='changeTrackingData'>
            <el-option
              v-for="item in collectionList"
              :key="item.id"
              :label="item.name"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('lang.Device')"
          :label-width="formLabelWidth"
          prop="peerId">
          <source-classify-select
            v-if='visible.device'
            :multiple='tracking.timeType == "2"'
            @changeSource="changeSource"></source-classify-select>
        </el-form-item>
        <el-form-item
          :label="$t('lang.duration')"
          v-if="tracking.timeType == '1'"
          prop="duration">
          <div class="date_time f_left pr">
            <el-date-picker
              v-model="tracking.startTime"
              popper-class="pick_date__map_time"
              type="datetime"
              @change="changeStartTime"
              :picker-options="{ disabledDate: disabledTime }">
            </el-date-picker>
          </div>
          <span class="iconfont icon-cc-arrow-circle-right f_left date-arrow"></span>
          <div class="date_time f_left">
            <el-date-picker
              v-model="tracking.endTime"
              popper-class="pick_date__map_time"
              type="datetime"
              @change="changeEndTime"
              :picker-options="{ disabledDate: disabledTime }">
            </el-date-picker>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <span
      slot="footer"
      class="dialog-footer">
      <el-button
        class="cancel"
        @click="cancel">{{ $t("lang.cancel") }}
      </el-button>

      <el-button
        class="search"
        v-repeat-click="{ event: search }">{{ $t("lang.search") }}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
import SourceClassifySelect from '@/components/SourceClassifySelect'
import 'vuex'

export default {
  props: {
    data: Object
  },
  data () {
    return {
      bindScroll: {
        onScroll: this.moreCollection,
        distance: 10,
        directive: '.el-select-dropdown__wrap.el-scrollbar__wrap'
      },
      show: false,
      parentLoad: false,
      formLabelWidth: '120px',
      searchId: [], // 设备下拉框数组
      modeData: [
        { value: '0', label: 'realTime' },
        { value: '1', label: 'history' },
        { value: '2', label: 'multiTracking' }
      ],
      tracking: {
        timeType: null,
        duration: '',
        peerId: '',
        startTime: '',
        endTime: ''
      },
      options: [],
      gpsDuration: 120,
      currentSourceStatus: null,
      collectionList: [],
      collectionParams: {
        pageSize: 15,
        currentPage: 1
      },
      hasNextPage: true,
      peerName: '',
      collection: {
        name: ''
      },
      multiplePeerIdArr: [],
      visible: {
        device: true
      }
    }
  },
  async mounted () {
    await this.getGpsDuration()
    this.requestDeviceList()
    document.addEventListener('click', this.clickEvent, true)
  },
  components: {
    SourceClassifySelect
  },
  computed: {
    rules () {
      return {
        duration: [{
          validator: (rule, value, callback) => {
            if (!value) callback(new Error(this.$t('lang.durEmpty')))
            if (value > this.gpsDuration * 60 * 1000 || value < 0) {
              callback(new Error(`${this.$t('lang.durationTime', { time: this.gpsDuration })}`))
            }
            callback()
          },
          trigger: ['change', 'blur']
        }]
      }
    }
  },
  watch: {
    show (val) {
      if (!val) return
      this.getCollectionlist()
    }
  },
  methods: {
    clickEvent (e) {
      const tar = e.srcElement || e.target
      try {
        const flag = document.querySelector('.source_classify_select').contains(tar)
        const clickInput = document.querySelector('.el-cascader__search-input').contains(tar)
        if (!flag && !clickInput) $('.source_classify_select').hide()
      } catch (error) { }
    },
    init () {
      this.show = true
    },
    cancel () {
      this.initData()
      this.show = false
    },
    // 初始化数据
    initData () {
      this.tracking = {
        timeType: null,
        duration: '',
        peerId: '',
        startTime: '',
        endTime: ''
      }
      this.currentSourceStatus = null
      this.collectionList = []
      this.collectionParams = {
        pageSize: 15,
        currentPage: 1
      }
      this.hasNextPage = true
      this.peerName = ''
      this.collection = {
        name: ''
      }
      this.multiplePeerIdArr = []
      this.visible = {
        device: true
      }
    },
    // 显示与隐藏collectin列表
    toggleCollection (val) {
      if (val) {
        this.collectionParams.currentPage = 1
        this.getCollectionlist()
      }
    },
    disabledTime (time) {
      let today = new Date().getTime()
      return time > today
    },
    // 懒加载
    moreCollection () {
      if (!this.hasNextPage) {
        return
      }
      this.collectionParams.currentPage++
      this.getCollectionlist()
    },
    // 获取collection列表
    getCollectionlist () {
      if (this.collectionParams.currentPage == 1) this.collectionList = []
      this.modeData[2].disabled = Boolean(!this.collectionList.length)
      this.axios.post('/ccp/tvucc-device/favorite/listFavorite', this.collectionParams, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
        .then(res => {
          if (res.status == 200 && res.data.errorCode == '0x0') {
            this.hasNextPage = res.data.result.hasNextPage
            if (res.data.result.isFirstPage) {
              this.collectionList = res.data.result.list
              this.modeData[2].disabled = Boolean(!this.collectionList.length)
              return
            }
            this.collectionList = [...this.collectionList, ...res.data.result.list]
            this.modeData[2].disabled = Boolean(!this.collectionList.length)
          }
        }).catch(() => {})
    },
    async getGpsDuration () {
      await this.axios
        .get('/ccp/tvucc-device/userDevice/queryGeoRecordSelection')
        .then((res) => {
          if (res.status == 200 && res.data.errorCode == '0x0') {
            this.gpsDuration = res.data.result.selectionTime
            localStorage.setItem('gpsDuration', this.gpsDuration)
            localStorage.setItem(
              'delayQueryTime',
              res.data.result.delayQueryTime
            )
            localStorage.setItem('mapStep', res.data.result.mapStep)
            localStorage.setItem('incrementalRangeTime', res.data.result.incrementalRangeTime)
            localStorage.setItem('manualIncrementTime', res.data.result.manualIncrementTime)
          }
        })
    },
    search () {
      if (!this.tracking.peerId) {
        if (this.tracking.timeType == '2') {
          this.$message.error(this.$t('lang.selectCollection'))
          return
        }
        this.$message.error(this.$t('lang.sourceEmpty'))
        return
      }
      if (this.tracking.timeType === null) {
        this.$message.error(this.$t('lang.modeTypeEmpty'))
        return
      }
      if (this.tracking.timeType == '2' && !this.multiplePeerIdArr.length) {
        this.$message.error(this.$t('lang.sourceEmpty'))
        return
      }
      if (this.tracking.timeType == '1' && (!this.tracking.duration || this.tracking.duration > this.gpsDuration * 60 * 1000 || this.tracking.duration < 0)) {
        this.$message.error(this.$t('lang.durEmpty'))
        return
      }
      if (this.currentSourceStatus == '0' && this.tracking.timeType != '1') {
        this.$message.error(this.$t('lang.offSourNotReal'))
        return
      }
      this.show = false
      if (typeof this.tracking.startTime == 'object' && this.tracking.startTime) {
        this.tracking.startTime = this.tracking.startTime.getTime()
      }
      if (typeof this.tracking.endTime == 'object' && this.tracking.endTime) {
        this.tracking.endTime = this.tracking.endTime.getTime()
      }
      let query = {
        timeType: this.tracking.timeType,
        sourceType: this.tracking.sourceType,
        peerId: this.tracking.peerId,
        startTime: this.tracking.startTime || null,
        endTime: this.tracking.endTime || null,
        peerName: this.peerName,
        colId: this.colId
      }
      if (this.tracking.timeType == '2') {
        query.multiple = this.multiplePeerIdArr.join(',')
        let collect = {
          peerId: query.peerId,
          startTime: query.startTime,
          endTime: query.endTime,
          id: this.colId,
          isSelect: true
        }
        sessionStorage.setItem('collect', JSON.stringify(collect))
      }
      this.$router.push({
        name: 'SourceMapTrack',
        query: query
      })
    },
    // 更改tracking的数据
    changeTrackingData (val) {
      this.collection.name = val.name
      this.tracking.peerId = val.peerId
      this.peerName = val.peerName
      this.tracking.startTime = val.startTime
      this.tracking.endTime = val.endTime
      this.tracking.sourceType = val.type
      this.colId = val.id
    },
    changeMode (val) {
      this.tracking.timeType = val
      this.tracking.peerId = ''
      // 重新注册设备选择框
      this.visible.device = false
      this.$nextTick(() => {
        this.visible.device = true
      })
      if (val === '0') {
        this.tracking.startTime = null
        this.tracking.endTime = null
      }
    },
    changeSource (val) {
      if (this.tracking.timeType == '2') {
        this.handleMultipleMode(val)
        return
      }
      this.tracking.sourceType = val[0][0]
      this.tracking.peerId = val[0][1]
      this.currentSourceStatus = val[0][2]
      this.peerName = val[0][3]
    },
    // 处理多选模式
    handleMultipleMode (val) {
      this.multiplePeerIdArr = []
      this.currentSourceStatus = null
      for (let i = 0; i < val.length; i++) {
        this.multiplePeerIdArr.push(`${val[i][0]}-${val[i][1]}`)
        // 找出offline的设备
        if (this.currentSourceStatus === '0') {
          continue
        }
        this.currentSourceStatus = val[i][2]
      }
    },
    changeStartTime (val) {
      if (this.tracking.endTime) {
        this.tracking.duration = this.tracking.endTime - val
      }
    },
    changeEndTime (val) {
      if (this.tracking.startTime) {
        this.tracking.duration = val - this.tracking.startTime
      }
    },
    changIcon (i) {
      let str = ''
      if (i.label == 'External') {
        str = '&#xe725;'
      } else if (i.label == 'TVUAnywhere') {
        str = '&#xe766;'
      } else if (i.label == 'TVUPack') {
        str = '&#xe641;'
      } else if (i.label == 'TVU Grid') {
        str = '&#xe6d4;'
      }
      return str
    },
    requestDeviceList () {
      let arr = []
      let url = '/ccp/tvucc-device/userDevice/queryUserSourcelist'
      let param = { condition: '' }
      this.axios.post(url, param, {
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
      }).then((res) => {
        this.parentLoad = false
        let data = res.data.result
        data.map((item, i) => {
          let obj = {}
          obj.label = Object.keys(data[i])[0]
          obj.value = Object.keys(data[i])[0]
          if (obj.label == 'TVU Anywhere') obj.label = 'TVUAnywhere'
          if (obj.label == 'TVU Pack') obj.label = 'TVUPack'
          if (obj.label == 'Grid') obj.label = 'TVU Grid'
          Object.values(data[i])[0].map((v) => {
            v.label = v.name
            v.value = v.peerId
            if (v.status == '0') {
              v.disabled = true
            }
          })
          obj.children = Object.values(data[i])[0]
          arr.push(obj)
        })
        this.searchId = JSON.parse(JSON.stringify(arr))
      }).catch(() => {
        this.parentLoad = false
      })
    }
  },
  beforeDestory () {
    document.removeEventListener('click', this.clickEvent, true)
  }
}
</script>
<style lang="less">
.pick_date__map_time {
  z-index: 2040 !important;
  top: 234px;
}
.select_device {
  z-index: 2040 !important;
}
</style>
<style lang="less" scoped>
.date_time {
  height: 40px;
  ::v-deep .el-date-editor {
    width: 245px;
  }
}

::v-deep .el-input__inner {
  &::-webkit-input-placeholder {
    color: rgb(120, 120, 120);
  }
}
.tracking_select {
  margin-top: 20px;
  ::v-deep .el-dialog {
    .el-dialog__header {
      .el-dialog__title {
        font-size: 20px;
        font-weight: bold;
        color: #dfdfdf;
        line-height: 24px;
      }
    }
    .el-dialog__body {
      .el-form-item {
        .el-form-item__label {
          font-size: 14px;
          color: #dfdfdf;
          padding: 0;
          line-height: 30px;
          margin-top: 10px;
        }
      }
    }
    .el-dialog__footer {
      .cancel {
        width: 100px;
      }
    }
  }

  .time {
    margin-top: 10px;
    border-radius: 2px;
    color: #000;
    text-align: center;
    margin-left: 5px;

    ::v-deep .el-input__prefix,
    ::v-deep .el-input__suffix {
      display: none;
    }
  }
  .cancel {
    width: 100px;
    height: 40px;
    background-color: #cccccc;
  }
  .search {
    background-color: #00cc66;
    width: 100px;
    height: 40px;
  }
  .date-arrow {
    width: 14px;
    height: 14px;
    margin: 2px 13px;
  }
  .interval {
    background: #fff;
    width: 16px;
    height: 3px;
    margin: 22px 10px;
    border-radius: 1px;
  }

  .content {
    text-align: left;
    font-size: 16px;
    max-height: 60vh;
    // overflow-y: auto;

    .item {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        display: block;
        margin-bottom: 5px;
      }
    }
    ::v-deep .select-box {
      width: 100%;

      .el-input__inner {
        border: 1px solid #444;
        background: #444;
        height: 46px;
        line-height: 46px;
      }
    }

    ::v-deep .el-select {
      width: 100%;

      .el-select__input {
        color: #fff;
      }
    }

    ::v-deep .selectRlist {
      width: 100%;
      //   height: 300px;
      //   overflow-y: auto;
    }
  }
}
</style>

<style lang="less" scoped>
.tracking_select {
  ::v-deep .el-form-item__error {
    margin-top: 15px;
  }
}
</style>
