<template>
  <el-cascader
    :placeholder="$t('lang.selectPlaceholder')"
    :options="searchId"
    v-model='selectSource'
    popper-class="source_classify_select"
    class='source_classify_select_box'
    filterable
    collapse-tags
    :props="{ expandTrigger: 'hover', multiple: multiple }"
    :debounce='600'
    :before-filter='beforeFilter'
    :filter-method='filterSource'
    :show-all-levels='false'
    @change="changeSource"
    :loading-text="$t('i18n_loading')"
    :loading="parentLoad"
    style="width: 100%">
    <template slot-scope="{ node, data }">
      <span
        class="iconfont"
        v-html="changIcon(data)"
        style="margin-right: 5px"></span>
      <span :title="node.isLeaf ? data.name + '(' + data.peerId + ')' : ''">
        <span
          :class="{
            red: data.status == '2',
            brow: data.status == '0',
            white: data.status == '1' || data.status == '3',
            ellipsis: node.isLeaf,
          }"
          :style="{ 'margin-left': node.isLeaf ? '5px' : '0px' }">
          {{ data.label }}
        </span>
      </span>
      <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
    </template>
  </el-cascader>
</template>
<script>
export default {
  props: {
    data: Object,
    multiple: {
      type: Boolean,
      default: false
    },
    device: Object,
    init: Boolean
  },
  data () {
    return {
      show: false,
      parentLoad: false,
      searchId: [], // 设备下拉框数组
      selectSource: [],
      filterSourceList: [],
      timer: null
    }
  },
  created () {
    this.requestDeviceList()
    if (!this.init) {
      this.selectSource = ''
      return
    }
    this.selectSource = [this.$route.query.sourceType, this.$route.query.peerId]
    if (this.$route.query.timeType == '2' && this.multiple) {
      this.handleMultiple()
    }
  },
  mounted () {
    if (this.multiple) {
      $('.source_classify_select_box .el-input__inner').attr('placeholder', '')
    }
  },
  watch: {
    multiple (val, oldVal) {
      if (val == oldVal) return
      val ? this.handleMultiple() : this.selectSource = [this.$route.query.sourceType, this.$route.query.peerId]
      let selectSource = JSON.parse(JSON.stringify(this.selectSource))
      if (!val) {
        selectSource[3] = this.$route.query.peerName
        selectSource = [selectSource]
      }
      this.$emit('changeSource', selectSource)
    },
    'device.peerId' (val, oldVal) {
      if (val == oldVal || this.device.timeType != '1') return
      this.selectSource = [this.device.sourceType, this.device.peerId]
      setTimeout(() => {
        $('.el-cascader').children('.el-input--suffix').children('.el-input__inner').val(this.device.peerName)
      })
    }
  },
  methods: {
    // 处理多路轨迹
    handleMultiple () {
      this.selectSource = []
      this.$route.query.multiple && this.$route.query.multiple.split(',').map(v => {
        this.selectSource.push([v.split('-')[0], v.split('-')[1]])
      })
    },
    beforeFilter (val) {
      // 初始化过滤后的源
      this.filterSourceList = []
    },
    // 筛选源
    filterSource (node, keyword) {
      if (node.label.toLowerCase().includes(keyword.toLowerCase())) {
        this.filterSourceList.push(node)
        if (this.timer) clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.changeColor()
        }, 100)
        return true
      }
    },
    // 更改筛选后源的颜色
    changeColor () {
      const filterListDom = document.querySelectorAll('.source_classify_select .el-cascader__suggestion-panel.el-scrollbar ul li span')
      const colorArr = ['brow', 'white', 'red', 'white']
      filterListDom && filterListDom.forEach(v => {
        this.filterSourceList.map(item => {
          if (item.text == v.innerHTML) {
            v.className = `ellipsis ${colorArr[item.data.status]}`
          }
        })
      })
      // 排序
      const doms = document.querySelectorAll('.source_classify_select .el-cascader__suggestion-panel.el-scrollbar ul li')
      let sortDoms = []
      const whiteDoms = []
      const redDoms = []
      const otherDoms = []
      doms && doms.forEach(v => {
        if ($(v).find('span').hasClass('white')) {
          whiteDoms.push(v)
        }
        if ($(v).find('span').hasClass('red')) {
          redDoms.push(v)
        }
        if (!$(v).find('span').hasClass('red') && !$(v).find('span').hasClass('white')) {
          otherDoms.push(v)
        }
      })
      sortDoms = [...whiteDoms, ...redDoms, ...otherDoms]
      $('.source_classify_select .el-cascader__suggestion-panel.el-scrollbar ul').html('')
      sortDoms.forEach(v => {
        $('.source_classify_select .el-cascader__suggestion-panel.el-scrollbar ul').append(v)
      })
    },
    changeSource (val) {
      val = JSON.parse(JSON.stringify(val))
      // 如果是单选模式，修改val格式
      if (!this.multiple) val = [val]
      const peerIdArr = []
      val.map(v => {
        peerIdArr.push(v[1])
      })
      for (let i = 0; i < this.searchId.length; i++) {
        const v = this.searchId[i]
        for (let j = 0; j < v.children.length; j++) {
          const item = v.children[j]
          if (peerIdArr.includes(item.peerId)) {
            val[peerIdArr.indexOf(item.peerId)][2] = item.status
            val[peerIdArr.indexOf(item.peerId)][3] = item.name
          }
        }
      }
      this.$emit('changeSource', val)
      $('.el-cascader .el-input__inner').attr('placeholder', '')
    },
    changIcon (i) {
      let str = ''
      if (i.label == 'External') {
        str = '&#xe725;'
      } else if (i.label == 'TVUAnywhere') {
        str = '&#xe766;'
      } else if (i.label == 'TVUPack') {
        str = '&#xe641;'
      } else if (i.label == 'TVU Grid') {
        str = '&#xe6d4;'
      }
      return str
    },
    requestDeviceList () {
      const arr = []
      const url = '/ccp/tvucc-device/userDevice/queryUserSourcelist'
      const param = { condition: '' }
      this.axios.post(url, param, {
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
      }).then((res) => {
        this.parentLoad = false
        const data = res.data.result
        data.map((item, i) => {
          const obj = {}
          obj.label = Object.keys(data[i])[0]
          obj.value = Object.keys(data[i])[0]
          if (obj.label == 'TVU Anywhere') obj.label = 'TVUAnywhere'
          if (obj.label == 'TVU Pack') obj.label = 'TVUPack'
          if (obj.label == 'Grid') obj.label = 'TVU Grid'
          Object.values(data[i])[0].map((v, index) => {
            v.label = v.name
            v.value = v.peerId
          })
          obj.children = Object.values(data[i])[0]
          arr.push(obj)
        })
        this.searchId = JSON.parse(JSON.stringify(arr))
      }).catch(() => {
        this.parentLoad = false
      })
    }
  }
}
</script>
<style lang="less">
.source_classify_select {
  background: #444;
  border: none;
  color: #fff;
  .el-cascader-menu:first-child {
    ul li .el-checkbox {
      display: none;
    }
  }
  .el-cascader__suggestion-item:focus,
  .el-cascader__suggestion-item:hover {
    background: #455f45;
  }
  .el-icon-check {
    color: #fff;
  }
  .white {
    color: #fff;
  }
  .red {
    color: rgb(255, 0, 0);
  }
  .brow {
    color: rgb(119, 119, 119);
  }
  .el-cascader-menu {
    background: #444;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.68);
    border-radius: 4px;
    li {
      color: #fff;
    }
    .red {
      color: rgb(255, 0, 0);
    }
    .brow {
      color: rgb(119, 119, 119);
    }
  }
}
.pick_date__map_time {
  z-index: 2040 !important;
  top: 234px;
}
.source_classify_select {
  z-index: 2040 !important;
}
</style>
<style lang="less" scoped>
.source_classify_select_box {
  ::v-deep .el-icon-arrow-down:before {
    content: '\e790';
  }
}
.date_time {
  height: 40px;
  ::v-deep .el-date-editor {
    width: 245px;
  }
}
::v-deep .el-input__inner {
  &::-webkit-input-placeholder {
    color: rgb(120, 120, 120);
  }
}
// ::v-deep .el-cascader__search-input::-webkit-input-placeholder{
//     color:#fff;
// }
::v-deep .el-cascader__search-input {
  background: transparent;
  color: #fff;
}
.add-source-dialog {
  margin-top: 20px;
  ::v-deep .el-dialog {
    .el-dialog__header {
      .el-dialog__title {
        font-size: 20px;
        font-weight: bold;
        color: #dfdfdf;
        line-height: 24px;
      }
    }
  }

  .time {
    margin-top: 10px;
    border-radius: 2px;
    color: #000;
    text-align: center;
    margin-left: 5px;

    ::v-deep .el-input__prefix,
    ::v-deep .el-input__suffix {
      display: none;
    }
  }
  .date-arrow {
    width: 14px;
    height: 14px;
    margin: 2px 13px;
  }
  .interval {
    background: #fff;
    width: 16px;
    height: 3px;
    margin: 22px 10px;
    border-radius: 1px;
  }

  .content {
    text-align: left;
    font-size: 16px;
    max-height: 60vh;
    // overflow-y: auto;

    .item {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        display: block;
        margin-bottom: 5px;
      }
    }
    ::v-deep .select-box {
      width: 100%;

      .el-input__inner {
        border: 1px solid #444;
        background: #444;
        height: 46px;
        line-height: 46px;
      }
    }
    ::v-deep .el-select {
      width: 100%;

      .el-select__input {
        color: #fff;
      }
    }

    ::v-deep .selectRlist {
      width: 100%;
      //   height: 300px;
      //   overflow-y: auto;
    }
  }
}
</style>
