<template>
  <el-dialog
    :title="$t('lang.trackCollection')"
    :visible.sync="show"
    width="620px"
    :center="true"
    :close-on-click-modal="false"
    class="add_collection"
    @close="cancel"
    style="z-index: 2005; margin-top: 100px">
    <div class="content">
      <div
        class='clearfix'
        style='margin-bottom: 24px;'>
        <div class="f_left">
          <span>{{ $t('lang.device') }}: </span>
          <span> {{ currentTracking.peerName }} </span>
        </div>
        <div class="f_right">
          {{ Number(currentTracking.startTime) | DATE('yyyy-MM-dd hh:mm:ss') }} - {{ Number(currentTracking.endTime) | DATE('yyyy-MM-dd hh:mm:ss') }}
        </div>
      </div>
      <el-form
        :model="tracking"
        ref="event"
        label-position="top"
        :rules="rules">
        <el-form-item :label="$t('lang.collectionName')">
          <el-input
            v-model="params.name"
            :placeholder="$t('lang.collectionName')"
            maxlength='32'></el-input>
          <p class='collection_prompt'>{{ $t('lang.collectionPrompt') }}</p>
        </el-form-item>
      </el-form>
    </div>
    <span
      slot="footer"
      class="dialog-footer">
      <el-button
        class="cancel"
        @click="cancel">{{ $t("lang.cancel") }}
      </el-button>
      <el-button
        class="search"
        type="success"
        v-repeat-click="{ event: save }">{{ $t("lang.save") }}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
import toTips from '@/assets/js/toTipsObj'

export default {
  props: {
    currentTracking: Object,
    show: Boolean
  },
  data () {
    return {
      params: {
        name: ''
      },
      tracking: {}
    }
  },
  computed: {
    rules () {
      return {
        peerId: [
          // { required: true, message: this.$t('lang.sourceEmpty'), trigger: ['change', 'blur'] }
        ],
        duration: [
          {
            validator: (rule, value, callback) => {
              if (!value) callback(new Error(this.$t('lang.durEmpty')))
              if (value > this.gpsDuration * 60 * 1000 || value < 0) {
                callback(new Error(`${this.$t('lang.durationTime', { time: this.gpsDuration })}`))
              }
              callback()
            },
            trigger: ['change', 'blur']
          }
        ]
      }
    }
  },
  methods: {
    cancel () {
      this.params.name = ''
      this.$emit('update:show', false)
    },
    save () {
      this.params.name = this.params.name.trim()
      if (!this.params.name) {
        this.$message.error(this.$t('lang.nameEmpty'))
        return
      }
      this.axios({
        method: 'post',
        url: '/ccp/tvucc-device/favorite/addFavorite',
        data: { ...this.params, ...this.currentTracking, type: this.$route.query.sourceType },
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
      }).then(res => {
        if (res.status == 200 && res.data.errorCode == '0x0') {
          this.cancel()
          return
        }
        let errorInfo = this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
        this.$message.error(errorInfo)
      }).catch(() => {})
    }
  }
}
</script>
<style lang="less">
.add_collection {
  .el-input__inner::placeholder {
    color: #777;
  }
  .collection_prompt {
    font-size: 14px;
    color: #777777;
    margin-top: 8px;
    height: 20px;
    line-height: 20px;
  }
  .el-dialog .el-dialog__footer button.el-button {
    width: 100px;
  }
}
</style>
