import { render, staticRenderFns } from "./addSource.vue?vue&type=template&id=2161896e&scoped=true"
import script from "./addSource.vue?vue&type=script&lang=js"
export * from "./addSource.vue?vue&type=script&lang=js"
import style0 from "./addSource.vue?vue&type=style&index=0&id=2161896e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2161896e",
  null
  
)

export default component.exports